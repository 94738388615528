@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: 'GT-Eesti-Display';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url(../public/fonts/GT-Eesti-Display-Light.woff2) format('woff2');
    }
    @font-face {
      font-family: 'GT-Eesti-Display';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(../public/fonts/GT-Eesti-Display-Regular.woff2) format('woff2');
    }
    @font-face {
      font-family: 'GT-Eesti-Display';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url(../public/fonts/GT-Eesti-Display-Medium.woff2) format('woff2');
    }
    @font-face {
      font-family: 'GT-Eesti-Display';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url(../public/fonts/GT-Eesti-Display-Bold.woff2) format('woff2');
    }
  }
  